
















































import Vue from 'vue'
import request from '@/utils/request'
import moment from 'moment'
const columns = [
  {title: '操作项', key: 'operationName'},
  {title: '操作菜单', key: 'contentName'},
  {title: '操作用户', key: 'username'},
  {title: '所在部门', key: 'officeName'},
  {title: 'URI', key: 'uri'},
  {title: '操作者IP', key: 'ip'},
  {title: '所属区域', key: 'areaName'},
  {title: '操作时间', slot: 'createTime'},
]
export default Vue.extend({
  data() {
    return {
      loading: false,
      pageNum: 1,
      pageSize: 10,
      formData: {},
      columns,
      data: {
        total: 0,
        list: [],
      },
      beginTime: new Date(moment().add(-30, 'days').valueOf()),
      endTime: new Date(moment().valueOf()),
      startTimeOptions: {
          disabledDate: (date: any) => {
              return date && date.valueOf() > new Date()
                || (date.valueOf() < moment().add(-3, 'years').valueOf());
          },
      },
      endTimeOptions: {
          disabledDate: (date: any) => {
              return date && date.valueOf() > new Date()
                || (date.valueOf() < moment().add(-3, 'years').valueOf());
          },
      },
      // 小时
      timeStartList: [],
      timeEndList: [],
      selectedStartHour: '',
      selectedEndHour: '',
      //
      logOperationList: [],
      logContentList: [],
    }
  },
  methods: {
    getData() {
      this.loading = true
      const { pageNum, pageSize} = this
      const start = moment(this.beginTime).format('YYYYMMDD') + this.selectedStartHour + '0000'
      const end = moment(this.endTime).format('YYYYMMDD') + this.selectedEndHour + '0000'
      const formList = {...this.formData, beginTime: moment(start, 'YYYYMMDDHH0000').valueOf(), endTime: moment(end, 'YYYYMMDDHH0000').valueOf()}
      const params = { pageNum, pageSize, ...formList}
      request.get('/api/sys/log/page', params)
      .then((data: any) => {
        this.data = data
        this.loading = false
      })
    },
    /* 改变pageNum */
    changPageNum(num: number) {
      this.pageNum = num
      this.getData()
    },
    checkData() {
      this.pageNum = 1
      this.getData()
    },
    formatDate(value: number) {
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    startTimeChange(e: any) {
        this.beginTime = e;
        this.endTimeOptions = {
            disabledDate: (date) => {
                return date.valueOf() > new Date() || date.valueOf() < new Date(e).valueOf()
            },
        }
        if (this.sameDate(e)) {
            this.timeStartList.splice(0)
            const endHour = moment().hour() + 1
            this.timeStartList = this.initTime(endHour)
            this.selectedStartHour = this.timeStartList[this.timeStartList.length - 1]
        } else {
            this.timeStartList = this.initTime(24)
        }
    },
    endTimeChange(e: any) {
        this.endTime = e;
        this.startTimeOptions = {
            disabledDate(date) {
                return date.valueOf() < moment().add(-3, 'years').valueOf()
                  || date.valueOf() > new Date(e).valueOf()
            },
        }
        if (this.sameDate(e)) {
            this.timeEndList.splice(0)
            const endHour = moment().hour() + 2
            this.timeEndList = this.initTime(endHour)
            this.selectedEndHour = this.timeEndList[this.timeEndList.length - 1]
        } else {
            this.timeEndList = this.initTime(24)
        }
    },
    sameDate(date: any) {
        return moment().year() === moment(date).year() && moment().month() === moment(date).month()
          && moment().date() === moment(date).date()
    },
    hourTime() {
        const endHour = moment().hour() + 2
        this.timeStartList = this.initTime(24)
        this.timeEndList = this.initTime(endHour)
        this.selectedStartHour = this.timeStartList[0]
        this.selectedEndHour = this.timeEndList[this.timeEndList.length - 1]
    },
    initTime(endHour: any) {
        const timeList: any = []
        for (let i = 0; i < endHour; i++) {
            const num = i < 10 ? '0' + i : i
            timeList.splice(i, 1, num)
        }
        return timeList
    },
    getLogContentList() {
      request.get('/api/sys/log/log_content', null)
      .then((data: any) => {
        this.logContentList = data
      })
    },
    getLogOperationList() {
      request.get('/api/sys/log/log_operation', null)
      .then((data: any) => {
        this.logOperationList = data
      })
    },
  },
  mounted() {
    this.hourTime()
    this.getLogContentList()
    this.getLogOperationList()
    this.getData()
  },
})
